import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import HeaderGeneric from '../components/HeaderGeneric'
import projectsHeader from '../assets/images/projects_header.jpeg'

class Generic extends React.Component {
  render() {
    const projectsTitle = 'Digital Tumbleweed: Projects'

    return (
      <Layout>
        <Helmet title={projectsTitle} />
        <div id="main">
          <img src={projectsHeader} alt="" className="header" />

          <HeaderGeneric title="Projects" />

          <section id="content" className="main projects">
            <h2>
              <span>
                <a
                  href="https://npmjs.com/package/bcrypt"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  bCrypt
                </a>
              </span>
              <img
                src="https://img.shields.io/npm/dm/bcrypt.svg"
                alt="bcrypt downloads last month"
              />
            </h2>
            <p>
              The bCrypt project for Node started as a way to secure passwords.
              Before offerings like auth0 and Cognito were big, we were all
              still storing passwords in our own databases. Other
              platforms/languages had an implementation of bCrypt already. But,
              Node didn't have one yet. I wanted to learn more about how addons
              worked in Node and was building an app that needed logins so I
              decided to implement the bCrypt algorithm for Node.
            </p>

            <p>
              After some time I was able to get a first pass working and, as
              they say, the rest is history. I was surprised by how fast it took
              off. I released the code and it filled a need in the community. A
              lot of people have contributed, helping the addon grow and
              stablize since those early days.
            </p>
          </section>

          <section id="content" className="main projects">
            <h2>
              <span>
                <a
                  href="https://npmjs.com/package/cron"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Cron
                </a>
              </span>
              <img
                src="https://img.shields.io/npm/dm/cron.svg"
                alt="cron downloads last month"
              />
            </h2>

            <p>
              Like the bCrypt project, cron was born out of a need. I was
              building a tool to repeat tasks and had been used to systems like
              Celery at the time. I wanted to have the ability to run code on a
              repeated basis inside a node process. I noticed that there was a
              Javascript project by{' '}
              <a
                href="https://j11y.io/"
                target="_blank"
                rel="noopener noreferrer"
              >
                James Padolsey
              </a>{' '}
              that brought this kind of behavior to javscript. So, I forked the
              project and started to get to work.
            </p>

            <p>
              Since those days the project has bloomed with help from many in
              the community. The principles of cron still apply, but the project
              has definitely seen a lot of activity over the years and for good
              reason. People use it a lot.
            </p>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Generic
